import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Seo } from "../components/";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Seo title="Gratitude" description="A dedication page to a few of the most impactful folks of my software career." keywords={[`gratitude`, `mentorship`, `thanks`]} mdxType="Seo" />
    <h1 {...{
      "id": "gratitude"
    }}>{`Gratitude`}</h1>
    <p>{`I'm immensely grateful to everyone who has encouraged me, mentored me, and
believed in me over the course of growing my career in software. There are
too many to count, but a few in particular have been absolutely vital. I
want to carve a little web space here to express that gratitude.`}</p>
    <h2 {...{
      "id": "dave-kennedy"
    }}>{`Dave Kennedy`}</h2>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/davidakennedy"
      }}>{`Dave`}</a>{` and I were co-workers at The Arc of the United States, the national
office of a non-profit organization serving people with intellectual and
development disabilities.`}</p>
    <p>{`For the first part of my time at The Arc, I went from a PR intern, to a
marketing intern, to a part-time employee doing graphic design, marketing
support and brand implementation and enforcement. At the time, Dave was a
one-man show running anything website related.`}</p>
    <p>{`I loved our chats about the web. He was the first person I ever talked to
who believed I could -- and should, if I wanted -- make a career out of
working on the web. Previously, I thought of my static site creation only
as tinkering, or through the lens of graphic design / marketing.`}</p>
    <p>{`When he ultimately left, he recommended that the non-profit hire me into
an open position on the web team. And they did. And that was my first web
job.`}</p>
    <p>{`Without Dave, I don't know that I would have ever believed that this
industry was for me. He also had (and still has) such a passion for web
accessibility, which hugely influenced the way I think about the web.`}</p>
    <h2 {...{
      "id": "maggie-epps"
    }}>{`Maggie Epps`}</h2>
    <p>{`I met `}<a parentName="p" {...{
        "href": "https://twitter.com/meppstimist"
      }}>{`Maggie`}</a>{` while we were both working at the same digital agency. She
was the first female engineer hired there (I believe ever, but at least
during my time there). At the time I was a technical project manager.`}</p>
    <p>{`When I came on board at the agency, I had been trying to pivot into a role
doing dev work where I could learn from other devs. For boring reasons I
won't elaborate on, I ended up deciding the PM role was a good move.`}</p>
    <p>{`Maggie advocated passionately to diversify the staff -- particularly the
engineering staff. And we had so many hugely important discussions that
ultimately resulted in me believing in myself enough to quit my job, move
to a different city, do a code bootcamp, and keep working toward being a
software developer.`}</p>
    <h2 {...{
      "id": "wes-todd"
    }}>{`Wes Todd`}</h2>
    <p>{`After I finished the bootcamp, `}<a parentName="p" {...{
        "href": "https://twitter.com/wesleytodd"
      }}>{`Wes`}</a>{` hired me as a junior software
developer. It wasn't my first "dev" job -- I'd been doing dev work for
years already at this point. (No one knows how to adequately explain this.
See "`}<a parentName="p" {...{
        "href": "https://css-tricks.com/the-great-divide/"
      }}>{`The Great Divide`}</a>{`" by Chris
Coyier). But he saw potential in me as a software developer,
and I'll forever be grateful to him for giving me the opportunity to
breathe new life into my career after finishing the bootcamp.`}</p>
    <p>{`He was an amazing manager, mentor and friend. He loves to have strong
opinions and debate things, and always offered detailed, constructive
feedback on PRs. He's super passionate about the open source community,
and has an energizing curiosity about the software ecosystem as a whole. I
learned so much from him, and I'm so grateful.`}</p>
    <h2 {...{
      "id": "aaron-gustafson"
    }}>{`Aaron Gustafson`}</h2>
    <p>{`I met `}<a parentName="p" {...{
        "href": "https://twitter.com/aarongustafson"
      }}>{`Aaron`}</a>{` when I saw him put out a call for "mentees" for the year of
2017, and I applied. I was one of his `}<a parentName="p" {...{
        "href": "https://www.aaron-gustafson.com/notebook/my-2017-mentees/"
      }}>{`first two mentees`}</a>{`.`}</p>
    <p>{`During that year, I dealt with a lot personally, including treating and
managing severe, debilitating depression. That, combined with imposter
syndrome, created a toxic mix that very nearly convinced me I was not cut
out for this industry. Aaron's continued guidance, support, and advice
frankly kept me in the industry.`}</p>
    <p>{`While I regret not being able to take advantage of that mentorship year in
the way I wish I could have, it was no doubt absolutely what I needed at
the time.`}</p>
    <h2 {...{
      "id": "jason-lengstorf"
    }}>{`Jason Lengstorf`}</h2>
    <p><a parentName="p" {...{
        "href": "https://twitter.com/jlengstorf"
      }}>{`Blitz Jackson`}</a>{`. It's hard to overstate how grateful I am to know Jason. I met Jason when I joined Gatsby. I was the first IC he managed at Gatsby -- technically organized in the Dev Rel area, but focused on supporting the ecosystem and documentation, and not so much traditionally Dev Rel-ish (`}<em parentName="p">{`Lol, rel-ish? Punny sticker idea, Jason?`}</em>{`) stuff.`}</p>
    <p>{`He never doubted me. Working alongside someone who whole-heartedly and energetically believes in you is so validating, it felt like it gave me super powers. I have debilitating anxiety when it comes to presenting information to large audiences -- but in 2019, organized under Dev Rel, I wanted to do my best. He actively supported me in preparing for all of the speaking engagements I pushed myself to undertake in that year.`}</p>
    <p>{`He taught me a lot about believing in yourself and having confidence in your credibility. And even when that wavers (as it does, for this chronic case of imposter syndrome I have) -- I can quiet my lizard brain a little bit better than I could before. And he taught me a lot about unapologetically advocating for what you believe in, and staying grounded in your sense of self, in that effort.`}</p>
    <h2 {...{
      "id": "marcy-sutton"
    }}>{`Marcy Sutton`}</h2>
    <p>{`I have known of `}<a parentName="p" {...{
        "href": "https://twitter.com/marcysutton"
      }}>{`Marcy`}</a>{` for longer than I can recall -- much, much longer than I have known her personally. Her educational content on accessibility is so high quality and so commonly cited that the IAAP counts it toward their continuing education credit.`}</p>
    <p>{`When she joined Gatsby, I was beside myself at the opportunity to work alongside her. I've gotten to learn from her in so many ways -- on a11y, on documentation and information architecture, on advocating for what you believe in, and on using your privilege to try to make an impact where you can. I've gotten to hop on video calls with her to audit UI for accessibility together, and tag her on Slack in questions about accessible UI development and patterns. I'm still in awe that I had the opportunity not only to benefit from having access to such a high caliber mentor (in a11y and beyond) but also to become her friend.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      